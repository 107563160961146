import React, { useContext } from "react";
import { MatrixComparisonSelector } from "../../components/controls/MatrixComparisonSelector";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { SessionContext } from "../../contexts/SessionContext";
import { MatrixComparison, SettingsContext, SortByType } from "../../contexts/SettingsContext";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { KpiMatrixView, defaultKpiList } from "../kpi-matrix/KpiMatrixView";
import i18n from "../../i18n";
import { getKpiDefinition } from "../../models/Kpi";
import Dropdown from "../../components/dropdown/Dropdown";
import { ObjectControls, getValidObjectControlsSettings } from "../../components/controls/ObjectControls";
import { KpiTypes } from "../../models/KpiTypes";
import { QuantityType, getAssignedQuantities } from "../../utils/Quantities";
import { QuantityDropdown, SortOrderSwitch } from "../../components/controls/KpiControlPrimitives";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { KpiMatrixHighlightControls } from "../../components/controls/HighlightControls";
import { Spotlight } from "../../components/spotlight/Spotlight";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { WorkplaceMatrixCellProps } from "../../components/matrix/WorkplaceMatrixCell";
import { MatrixProcessStats } from "../../components/matrix/stats/MatrixWorkplaceStats";

export function WorkplaceComparisonView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const yieldQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false);
    const baseQuantities = yieldQuantities.map(q => q.baseQuantity);

    return <TabbedView
        subtitle={<div className="text viewSubtitleSpinner">
            {settings.kpiMatrix.machines.length === 0 && i18n.t("common.workplaceSelection")}
            {settings.kpiMatrix.machines.length === 1 && settings.kpiMatrix.machines[0]}
            {settings.kpiMatrix.machines.length > 1 && i18n.t("common.numWorkplaces", { count: settings.kpiMatrix.machines.length })}
        </div>}
        breadcrumbs={[{
            label: "common.analyses",
            url: `/projects/${session.projectId}/analyses`,
        }, {
            label: "common.analysesView.processWorkplace"
        }]}
        pages={[
            {
                tabTitle: "common.kpiMatrix",
                tabSlug: "comparison",
                spotlightId: "Kpi-Matrix",
                activator: (preferences) => {
                    const temp = viewSettingsInitialization(session, settings, preferences, {
                        sortBy: [SortByType.Kpi, SortByType.Alphabetical, SortByType.Kpi],
                        kpis: defaultKpiList.map(k => k.kpiType),
                        quantities: baseQuantities,
                    });

                    if (!temp)
                        return;

                    settings.mergeSet(ObjectMerger.mergeObjects([
                        temp,
                        getValidObjectControlsSettings(session, settings),
                    ]));
                },
                controller: <div className="controls">
                    <ObjectControls showContextSwitch={false} />
                    <MatrixComparisonSelector enabledComparisons={[MatrixComparison.None, MatrixComparison.Planning,
                        MatrixComparison.AbsoluteDeviation, MatrixComparison.RelativeDeviation]} />
                    <div className="controls">
                        <KpiMatrixHighlightControls disabled={settings.kpiMatrix.machines.length <= 1 && settings.kpiMatrix.comparison === MatrixComparison.None} label="common.highlights" />
                        <div className="section sortOrder">
                            <div className="title">
                                {i18n.t("kpiMatrix.sortByKpi")}
                                <Spotlight id={buildControllerSpotlightId(location.pathname, ["SortBy"])} className="mls" />
                            </div>
                            <Dropdown
                                options={defaultKpiList.map(t => {
                                    return {
                                        label: i18n.t(getKpiDefinition(t.kpiType, { session, settings })?.label ?? ""), value: t.kpiType
                                    };
                                })}
                                value={{ value: settings.kpi.selectedKpi, label: i18n.t(getKpiDefinition(settings.kpi.selectedKpi, { session, settings })?.label ?? "") }}
                                onChange={(e) => {
                                    const kpi = e!.value as KpiTypes;
                                    settings.setKpiState({ selectedKpi: kpi });
                                }}
                            />
                            <SortOrderSwitch />
                        </div>
                        <QuantityDropdown quantities={yieldQuantities} />
                    </div>
                </div>,
                stats: <MatrixProcessStats />,
                isVisible: true,
                content: <>
                    <KpiMatrixView />
                    <BackButtonTrayElement />
                </>,
                selectionTrigger: (settings) => {
                    return (settings.selection.matrixElement as WorkplaceMatrixCellProps)?.value !== undefined;
                },
            }
        ]}
    />;
}