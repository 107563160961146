import React, { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { StatsRowValue } from "../../stats-section/StatsRowValue";
import { StatsSection, StatsSubSection } from "../../stats-section/StatsSection";
import { KpiTypes, StatisticTypes } from "../../../models/KpiTypes";
import EquipmentStatsLineGraph from "../../graph/EquipmentStatsLineGraph";
import { KpiDefinition, getKpiDefinition, getKpiSpotlightIdNode, getUnit } from "../../../models/Kpi";
import { WorkplaceMatrixCellProps } from "../WorkplaceMatrixCell";
import { getKpiList } from "../../../views/kpi-matrix/EquipmentMatrixView";
import i18n from "../../../i18n";
import { defaultKpiList } from "../../../views/kpi-matrix/KpiMatrixView";

export function MatrixEquipmentStats() {

    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const kpiList = getKpiList(session, settings);

    const element = settings.selection.matrixElement as WorkplaceMatrixCellProps;

    if (!element) {
        return null;
    }

    const selectedKpiDefinition = getKpiDefinition(element.kpi as KpiTypes, {settings, session});
    //get the statistic of the selected kpi that is used in the matrix 
    const kpiStatistic = kpiList.find(kpi => kpi.kpiType === element.kpi)?.statistic;

    if (!selectedKpiDefinition || !kpiStatistic) {
        return null;
    }
    
    return WorkplaceMatrixStats({
        title: element.workplace?.name,
        isLoading: false,
        mean: element.value,
        kpiDefinition: selectedKpiDefinition,
        statistic: kpiStatistic,
        enableLineChart: true, 
    }, settings.kpi.statistic);
}

export function MatrixProcessStats() {

    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const element = settings.selection.matrixElement as WorkplaceMatrixCellProps;

    if (!element) {
        return null;
    }

    const selectedKpiDefinition = getKpiDefinition(element.kpi as KpiTypes, {settings, session});
    //get the statistic of the selected kpi that used in the matrix 
    const kpiStatistic = defaultKpiList.find(kpi => kpi.kpiType === element.kpi)?.statistic;

    if (!selectedKpiDefinition || !kpiStatistic) {
        return null;
    }
    
    return WorkplaceMatrixStats({
        title: element.workplace?.name,
        isLoading: false,
        mean: element.value,
        kpiDefinition: selectedKpiDefinition,
        statistic: kpiStatistic,
    }, settings.kpi.statistic);
}

function WorkplaceMatrixStats(data: {
    title: string | undefined,
    isLoading: boolean | undefined,
    mean: number | undefined,
    kpiDefinition: KpiDefinition | undefined,
    statistic: StatisticTypes | undefined,
    enableLineChart?: boolean | undefined,
}, selectedStatistic: StatisticTypes) {
    const session = useContext(SessionContext);
    if (!data.kpiDefinition) {
        return null;
    }
    return <StatsSection title={data.title}>
        <StatsSubSection title={data.kpiDefinition?.label} spotlightId={getKpiSpotlightIdNode(session, data.kpiDefinition?.id, ["Node"])}>
            <StatsRowValue label={i18n.t(`common.statistics.${data.statistic}`) ?? "common.value"}  value={data.mean} unit={getUnit(data?.kpiDefinition?.unit, data?.statistic)} isHighlight={selectedStatistic === StatisticTypes.Mean} isLoading={data.isLoading} />
            {/* Line chart */}
        </StatsSubSection>
        {data.enableLineChart && <EquipmentStatsLineGraph />}

    </StatsSection>;
}